import React from 'react';
import { SurveyQuestionComponent } from './components/SurveyQuestion';
import { SurveyAnswer, SurveyConfig, SurveyResult } from './types';
import styled from '@emotion/styled';
interface Props {
  config: SurveyConfig;
  onEnded: (response: SurveyResult) => void;
}

const SurveyTitle = styled.h2`
  margin-bottom: 3rem;
  text-align: center;
`;

const SurveyContainer = styled.div`
  display: grid;
  grid-template:
    'survey-title' 4rem
    'survey-body' auto /
    auto;
`;

export const SurveyComponent: React.FC<Props> = ({
  config,
  onEnded,
  ...props
}) => {
  const [results, setResults] = React.useState<SurveyResult>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState<
    number
  >(0);

  const currentQuestion = React.useMemo(
    () => config.questions[currentQuestionIndex] || null,
    [config.questions, currentQuestionIndex]
  );
  React.useEffect(() => {
    if (config.questions.length === 0) {
      onEnded(results);
    }
  });

  const onAnswer = React.useCallback(
    (answer: SurveyAnswer) => {
      setResults({
        ...results,
        ...{ [currentQuestion?.id]: answer === '' ? ' ' : answer },
      });
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (currentQuestionIndex >= config.questions.length - 1) {
        onEnded({
          ...results,
          ...{ [currentQuestion?.id]: answer === '' ? ' ' : answer },
        });
      }
    },
    [
      currentQuestionIndex,
      results,
      currentQuestion,
      config.questions.length,
      onEnded,
    ]
  );

  const nodeToDisplay = React.useMemo(() => {
    if (currentQuestionIndex < config.questions.length) {
      return (
        <SurveyQuestionComponent
          key={currentQuestion.id}
          question={currentQuestion}
          onAnswer={onAnswer}
          isLastQuestion={currentQuestionIndex === config.questions.length - 1}
        />
      );
    }
    return <div>Survey Completed</div>;
  }, [
    currentQuestionIndex,
    currentQuestion,
    config.questions.length,
    onAnswer,
  ]);

  return (
    <SurveyContainer {...props}>
      <SurveyTitle>Survey</SurveyTitle>
      {nodeToDisplay}
    </SurveyContainer>
  );
};
